














































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { DutyModel } from '@/models/organization/DutyModel';
import { updateOrgGroup, createOrgGroup } from '@/api/system/OrganizationApi';
@Component({
  name: 'DutyForm'
})
export default class DutyForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前数据
  @Prop({ default: null }) currtent!: DutyModel;
  // 传过来的数据
  @Prop({ default: {} }) selectCompanyData;
  // 表单加载状态
  formLoading: boolean = false;
  // 表单数据
  dutyData: any = {
    disabled: true
  };
  // 验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_please_enter_the_job_name'),
      trigger: 'blur'
    },
    shortName: {
      required: true,
      message: this.$t('lang_please_enter_the_duty_short_name'),
      trigger: 'blur'
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    },
    disabled: {
      required: true,
      message: this.$t('lang_please_select_a_status'),
      trigger: 'blur'
    }
  };

  created() {
    if (this.currtent) {
      this.dutyData = { ...this.currtent };
    } else {
      this.dutyData = {
        disabled: true,
        type: '8',
        pid: this.selectCompanyData.id,
        tenantId: this.selectCompanyData.tenantId
      };
    }
  }

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let result;
      if (this.currtent) {
        result = updateOrgGroup(this.dutyData);
      } else {
        result = createOrgGroup(this.dutyData);
      }

      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
