var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.currtent ? "编辑岗位" : "新增岗位",
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.dutyData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.dutyData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.dutyData, "name", $$v)
                          },
                          expression: "dutyData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位简称", prop: "shortName" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.dutyData.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dutyData, "shortName", $$v)
                          },
                          expression: "dutyData.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.dutyData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.dutyData, "sort", $$v)
                              },
                              expression: "dutyData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_state"),
                            prop: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_a_status"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.dutyData.disabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dutyData, "disabled", $$v)
                                },
                                expression: "dutyData.disabled",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_enable"),
                                  value: true,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_disable_"),
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }